import CMSLayout from '@/layout/CMSLayout.vue';
export const accountRouter = {
    path: '/account',
    component: CMSLayout,
    children: [
        {
            path: '/accounts',
            name: 'AccountPage.vue',
            component: () => import('../page/AccountPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                isSuperAdmin:true,
                title: 'Accounts'
            },
        },
    ],
};
