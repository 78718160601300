
import { Vue, Options } from 'vue-class-component'

@Options({
  props: {
    isCollapse: false
  }
})
export default class AppMain extends Vue {
  get reRender() {
    return this.$store.state.reRender
  }
}
