import CMSLayout from '@/layout/CMSLayout.vue';

export const packageRouter = {
    path: '/account',
    component: CMSLayout,
    children: [
        {
            path: '/package',
            name: 'PackagePage',
            // lazy load
            component: () => import('../page/PackagePage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: false,
                title: 'Packages'
            },
        },
    ],
}
