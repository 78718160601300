import CMSLayout from '@/layout/CMSLayout.vue';

export const platformRouter = {
    path: '/account',
    component: CMSLayout,
    children: [
        {
            path: '/platform',
            name: 'PlatformPage',
            // lazy load
            component: () => import('../page/PlatformPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: false,
                title: 'Platform'
            },
        },
    ],
}
