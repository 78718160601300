
import { Vue, Options } from 'vue-class-component'
import AppSidebar from './components/AppSidebar.vue'
import AppNavbar from './components/AppNavbar.vue'
import AppNavbarStarted from './components/AppNavbarStarted.vue'
import AppMain from './components/AppMain.vue'

@Options({
  components: {
    AppSidebar,
    AppNavbar,
    AppMain,
    AppNavbarStarted
  },
  watch: {
    $route: {
      handler: function (to): void {
        // Do something here.
        document.title = `${this.$router.currentRoute.value.meta.title} - iOmniC`
        this.pageTitle = this.$router.currentRoute.value.meta.title
      },
      immediate: true
    }
  }
})
export default class CMSLayout extends Vue {
  checkAuthenticDone = false
  accounts: any = []
  accountNotBanned: any = []
  isCollapse = false
  pageTitle: any
  isShowChangeAccount = false
  authentic: any

  async created() {
    this.pageTitle = this.$router.currentRoute.value.meta.title
    document.title = `${this.$router.currentRoute.value.meta.title} - iOmniC`
    const authenticResponse = await this.$store.dispatch('auth/authentic')
    this.authentic = authenticResponse
    if (!authenticResponse.email) {
      await this.$router.push({ name: 'LoginPage' })
      return
    }
    await this.$store.dispatch('auth/setDataLogin', authenticResponse)
    this.accounts = authenticResponse.account

    if (localStorage.getItem('isShowChangeAccount') == null) {
      const tokenUser = await this.$store.dispatch('auth/getTokenUser')
      if (!tokenUser) {
        await this.$router.push({ name: 'LoginPage' })
        return
      }

      if (this.accounts.length == 1) {
        if (
          this.accounts[0].banned.length > 0 &&
          this.accounts[0].banned.includes(authenticResponse.id)
        ) {
          this.$message.warning(
            "You don't have any account, please contact your administrator!!!"
          )
          localStorage.removeItem('tokenUser')
          localStorage.removeItem('account')
          localStorage.removeItem('isShowChangeAccount')
          await this.$router.push({ name: 'LoginPage' })
          return
        } else {
          await this.$store.dispatch(
            'auth/setAccountSelected',
            this.accounts[0]
          )
          this.isShowChangeAccount = false
          await this.checkAuthentic()
        }
      } else if (this.accounts.length > 1) {
        this.accounts.forEach((acc: any, i: any) => {
          if (acc.banned.length > 0) {
            if (!acc.banned.includes(authenticResponse.id)) {
              this.accountNotBanned.push(acc)
            }
          } else this.accountNotBanned.push(acc)
        })
        if (this.accountNotBanned.length == 1) {
          this.accounts = this.accountNotBanned
          await this.$store.dispatch(
            'auth/setAccountSelected',
            this.accounts[0]
          )
          this.isShowChangeAccount = false
          await this.checkAuthentic()
        } else if (this.accountNotBanned.length > 1) {
          this.accounts = this.accountNotBanned
          this.isShowChangeAccount = true
        } else {
          this.$message.warning(
            "You don't have any account, please contact your administrator!!!"
          )
          localStorage.removeItem('tokenUser')
          localStorage.removeItem('account')
          localStorage.removeItem('isShowChangeAccount')
          await this.$router.push({ name: 'LoginPage' })
          return
        }
      }
    } else this.checkAuthenticDone = true
    this.accounts.forEach(async (acc: any) => {
      if (acc['_id'] === localStorage.getItem('account')) {
        await this.$store.dispatch('auth/setAccountSelected', acc)
      }
    })
    //  await this.checkAuthentic();
  }
  async checkAuthentic() {
    if (this.accounts) {
      this.setAccountStore()
    }
    // if (this.$store.state.auth.dataLogin.type || this.$store.state.auth.dataLogin === 1) {
    //     if (this.$router.) {
    //     }
    // }
    this.checkAuthenticDone = true
  }

  setAccountStore() {
    let accountStore = localStorage.getItem('account')

    if (accountStore) {
      const checkExistAccount = this.accounts.find(
        (acc: any) => acc['_id'] == accountStore
      )

      if (!checkExistAccount) {
        localStorage.setItem('account', this.accounts[0]['_id'])
        localStorage.setItem('isShowChangeAccount', 'true')
      }
    } else {
      localStorage.setItem('account', this.accounts[0]['_id'])
      localStorage.setItem('isShowChangeAccount', 'true')
    }
  }

  handleChooseAccount(account: any) {
    this.accounts.forEach(async (acc: any) => {
      if (acc['_id'] === account['_id']) {
        await this.$store.dispatch('auth/setAccountSelected', acc)
      }
    })
    localStorage.setItem('account', account['_id'])
    localStorage.setItem('isShowChangeAccount', 'true')
    this.checkAuthenticDone = true
    this.isShowChangeAccount = false
  }

  handleSidebar() {
    if (this.isCollapse) {
      this.isCollapse = false
    } else {
      this.isCollapse = true
    }
  }

  backToLogin() {
    localStorage.removeItem('tokenUser')
    localStorage.removeItem('account')
    localStorage.removeItem('isShowChangeAccount')
    this.$router.push({ name: 'LoginPage' })
  }
}
