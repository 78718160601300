import CMSLayout from '@/layout/CMSLayout.vue';
export const TemplateRouter = {
    path: '/templates',
    component: CMSLayout,
    children: [
        {
            path: '/templates',
            name: 'TemplatePage',
            component: () => import('../page/template.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                isSuperAdmin:true,
                title: 'Templates'
            },
        },
    ],
};
