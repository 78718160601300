import CMSLayout from '@/layout/CMSLayout.vue'

export const userRouter = {
  path: '/account',
  component: CMSLayout,
  children: [
    {
      path: '/users',
      name: 'UserPage',
      // lazy load
      component: () => import('../page/UserPage.vue'),
      meta: {
        onlyWhenLoggedOut: true,
        public: false,
        title: 'Users'
      }
    }
  ]
}
