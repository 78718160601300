export const auth = {
    tilePage: 'Auth',
    button: {
        signIn: 'Sign In',
        signUp: 'Sign Up'
    },
    signIn: {
        title: 'Connect with Us',
        form: {
            email: 'Email',
            password: 'Password',
            button: 'Sign In',
            validate: {
                emailRequired: 'Please input email address',
                emailCorrect: 'Please input correct email address',
                passwordRequired: 'Please input password',
                passwordCorrect: 'Length greater than 3 characters'
            },
        },
    },
};
