import CMSLayout from '@/layout/CMSLayout.vue'

export const serviceStatusRouter = {
  path: '/',
  component: CMSLayout,
  children: [
    {
      path: '/service-status',
      name: 'ServiceStatusPage',
      // lazy load
      component: () => import('../page/ServiceStatusPage.vue'),
      meta: {
        onlyWhenLoggedOut: true,
        public: true,
        title: 'Service Status'
      }
    }
  ]
}
