import CMSLayout from '@/layout/CMSLayout.vue';
export const accountBillingRouter = {
    path: '/account',
    component: CMSLayout,
    children: [
        {
            path: '/account-billing',
            name: 'AccountBillingPage.vue',
            component: () => import('../page/AccountBillingPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                isSuperAdmin:true,
                title: 'Account Billing'
            },
        },
    ],
};
