import CMSLayout from '@/layout/CMSLayout.vue';
export const scriptCustomRouter = {
    path: '/account',
    component: CMSLayout,
    children: [
        {
            path: '/script-custom',
            name: 'ScriptCustomPage',
            component: () => import('../page/ScriptCustomPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                isSuperAdmin: true,
                title: "Customization Page"
            },
        },
    ],
};