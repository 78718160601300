import CMSLayout from '@/layout/CMSLayout.vue';
export const cronManagerRouter = {
    path: '/account',
    component: CMSLayout,
    children: [
        {
            path: '/cron-manager',
            name: 'CronManagerPage.vue',
            component: () => import('../page/CronManagerPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                isSuperAdmin: true,
                title: "Cron Manager"
            },
        },
    ],
};