
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Sidebars from './sidebar'
import { useRoute } from 'vue-router'

@Options({
  emits: ['updateDrawer']
})
export default class MobileDrawer extends Vue {
  sidebars: any = []
  listPlatformExist: any = []
  route = useRoute()
  currentRouter = ''
  icon = 'el-icon-caret-left'
  existShopifyConnect = false
  accountSelected: any
  accountSelectedId = ''
  accounts: any = []
  accountNotBanned: any = []
  isShowUserSidebar = false
  showDrawer = true

  @Prop(Boolean) drawerOpen!: boolean

  localDrawerOpen: boolean = this.drawerOpen

  @Watch('drawerOpen')
  onDrawerOpenChange(newVal: boolean): void {
    // console.log(`newVal: ${newVal}`)

    this.localDrawerOpen = newVal
    this.$emit('updateDrawer', newVal)
  }

  async created() {
    this.menuSidebar()

    this.currentRouter = this.route.path.replace('/', '')

    const authenticResponse = await this.$store.state.auth.dataLogin

    this.accountSelected = await this.$store.state.auth.accountSelected
    this.accountSelectedId = this.accountSelected._id
    this.accounts = authenticResponse.account

    if (this.accounts.length > 0) {
      this.accounts.forEach((acc: any) => {
        if (acc.banned.length > 0) {
          if (!acc.banned.includes(authenticResponse.id)) {
            this.accountNotBanned.push(acc)
          }
        } else this.accountNotBanned.push(acc)
      })
      localStorage.setItem(
        'isShowUserSidebar',
        this.isShowUserSidebar.toString()
      )
    }
    this.accounts = this.accountNotBanned.map((acc: any) => {
      return {
        name: acc.name,
        id: acc._id
      }
    })

    if (
      this.accountSelected.connectionData[0].connections[0].type == 'shopify' &&
      authenticResponse.type == 0
    ) {
      this.sidebars.push(
        {
          name: 'My Subscriptions',
          url: 'subscriptions',
          icon: 'wallet',
          isSuper: false,
          isClient: true
        },
        {
          name: 'My Profile',
          icon: 'address-card',
          isSuper: false,
          isClient: true,
          children: {
            subItem: [{ name: 'My Details', url: 'account-detail' }]
          }
        }
      )
    } else {
      this.accountSelected.connectionData.forEach((goi: any) => {
        goi.connections.forEach((connection: any) => {
          if (
            !this.listPlatformExist.includes(connection.type) &&
            !connection.deletedDate
          )
            this.listPlatformExist.push(connection.type)
          if (connection.type == 'shopify' && !connection.deletedDate) {
            this.existShopifyConnect = true
          }
        })
      })

      if (this.existShopifyConnect) {
        if (this.listPlatformExist.length >= 3 && authenticResponse.type == 0) {
          this.sidebars.push({
            name: 'My Profile',
            icon: 'address-card',
            isSuper: false,
            isClient: true,
            children: {
              subItem: [
                { name: 'My Details', url: 'account-detail' },
                { name: 'My Payments', url: 'account-payment' }
              ]
            }
          })
        }

        if (this.listPlatformExist.length == 2 && authenticResponse.type == 0) {
          this.sidebars.push(
            {
              name: 'My Subscriptions',
              url: 'subscriptions',
              icon: 'wallet',
              isSuper: false,
              isClient: true
            },
            {
              name: 'My Profile',
              icon: 'address-card',
              isSuper: false,
              isClient: true,
              children: {
                subItem: [{ name: 'My Details', url: 'account-detail' }]
              }
            }
          )
        }
      } else {
        if (authenticResponse.type == 0) {
          this.sidebars.push({
            name: 'My Profile',
            icon: 'address-card',
            isSuper: false,
            isClient: true,
            children: {
              subItem: [
                { name: 'My Details', url: 'account-detail' },
                { name: 'My Payments', url: 'account-payment' }
              ]
            }
          })
        }
      }
    }
    if (this.accountSelected.isFreeAccount) {
      this.sidebars = this.sidebars.filter((item: any) => {
        return item.name != 'My Subscriptions'
      })
    }
  }

  menuSidebar() {
    Sidebars[0].forEach((menu) => {
      if (
        this.$store.state.auth.dataLogin.type ||
        this.$store.state.auth.dataLogin === 1
      ) {
        if (menu.isSuper) {
          this.sidebars.push(menu)
        }
      } else {
        if (menu.isClient) {
          this.sidebars.push(menu)
        }
      }
    })
  }
  handleClose(done: () => void) {
    this.onDrawerOpenChange(false)

    done()
  }

  handleChangeAccount() {
    // console.log(this.accountSelectedId)
    localStorage.setItem('account', this.accountSelectedId)
    window.location.reload()
  }
}
