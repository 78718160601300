import HomeLayout from '@/layout/HomeLayout.vue';
let token
const publicRouters = {
    path: '/',
    component: HomeLayout,
    children: [
        {
            path: '/',
            name: 'LoginPage',
            // lazy load
            component: () => import('../page/Auth.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Login Page'
            },
        },
        {
            path: `/?token:${token}`,
            name: 'ModalNewPassword',
            // lazy load
            component: () => import('../components/ModalNewPassword.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true
            },
        },
        {
            path: `/verify-email`,
            name: 'VerifyEmail',
            props: true,
            // lazy load
            component: () => import('../components/VerifyEmail.vue'),
            meta: {
                onlyWhenLoggedOut: false,
                public: false,
                title: 'Verify Email'
            },
        },
    ],
};
export const authRouters = [publicRouters];
