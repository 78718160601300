import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_el_menu_item = _resolveComponent("el-menu-item")
  const _component_el_submenu = _resolveComponent("el-submenu")
  const _component_el_menu = _resolveComponent("el-menu")
  const _component_el_drawer = _resolveComponent("el-drawer")

  return (_openBlock(), _createBlock(_component_el_drawer, {
    modelValue: _ctx.localDrawerOpen,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.localDrawerOpen = $event)),
    "show-close": false,
    direction: "ltr",
    size: "70%",
    title: "IOMNIC",
    "before-close": _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_menu, {
        "default-active": _ctx.currentRouter,
        class: "",
        "background-color": "#fff",
        "text-color": "#2c3e50",
        router: true,
        onSelect: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDrawerOpenChange(false)))
      }, {
        default: _withCtx(() => [
          (_ctx.accounts.length > 1)
            ? (_openBlock(), _createBlock(_component_el_select, {
                key: 0,
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleChangeAccount())),
                modelValue: _ctx.accountSelectedId,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.accountSelectedId = $event)),
                style: {"margin":"12px"},
                placeholder: "Select",
                size: "large"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.accounts, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.id,
                      label: item.name,
                      value: item.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.sidebars, (item, itemIndex) => {
            return (_openBlock(), _createBlock(_Fragment, { key: itemIndex }, [
              (item.children)
                ? (_openBlock(), _createBlock(_component_el_submenu, {
                    key: 0,
                    index: item.url
                  }, {
                    title: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        class: "icon-sidebar",
                        icon: item.icon,
                        "fixed-width": ""
                      }, null, 8, ["icon"]),
                      _createVNode("span", null, _toDisplayString(item.name), 1)
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(item.children.subItem, (sub, subindex) => {
                        return (_openBlock(), _createBlock(_component_el_menu_item, {
                          key: subindex,
                          index: item.url + '/' + sub.url
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(sub.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["index"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["index"]))
                : (_openBlock(), _createBlock(_component_el_menu_item, {
                    key: 1,
                    index: item.url
                  }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        class: "icon-sidebar",
                        icon: item.icon,
                        "fixed-width": ""
                      }, null, 8, ["icon"])
                    ]),
                    _: 2
                  }, 1032, ["index"]))
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["default-active"])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}