import CMSLayout from '@/layout/CMSLayout.vue';

export const settingRouter = {
    path: '/',
    component: CMSLayout,
    children: [
        {
            path: 'setting',
            name: 'SettingPage',
            // lazy load
            component: () => import('../page/SettingPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Settings'
            },
        },
        {
            path: 'crons',
            name: 'CronPage',
            // lazy load
            component: () => import('../components/Crons.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Crons'
            },
        },
    ],
}