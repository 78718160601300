import CMSLayout from '@/layout/CMSLayout.vue';

export const connectionRouter = {
    path: '/',
    component: CMSLayout,
    children: [
        {
            path: '/connections',
            name: 'ConnectionPage',
            // lazy load
            component: () => import('../page/ConnectionPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Connections'
            },
        },
        {
            path: '/shipment-mapping',
            name: 'ConnectionShipmentPage',
            // lazy load
            component: () => import('../page/ConnectionShipmentPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Shipment Mapping'
            },
        },
        {
            path: '/payment-mapping',
            name: 'ConnectionPaymentPage',
            // lazy load
            component: () => import('../page/ConnectionPaymentPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Payment Mapping'
            },
        },
    ],
}
