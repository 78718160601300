import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")
  const _component_router_view = _resolveComponent("router-view")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_loading, null, null, 512), [
      [
        _directive_loading,
        _ctx.showLoading,
        void 0,
        {
          fullscreen: true,
          lock: true
        }
      ]
    ]),
    _createVNode(_component_router_view)
  ], 64))
}