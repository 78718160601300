import CMSLayout from '@/layout/CMSLayout.vue';
export const SubscriptionRouter = {
    path: '/subscriptions',
    component: CMSLayout,
    children: [
        {
            path: '/subscriptions',
            name: 'SubscriptionPage',
            component: () => import('../page/subscription.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                isSuperAdmin:false,
                title: 'Subscriptions'
            },
        },
    ],
};
