import CMSLayout from '@/layout/CMSLayout.vue'

export const syncErrorsRouter = {
  path: '/',
  component: CMSLayout,
  children: [
    {
      path: '/sync-errors',
      name: 'SyncErrorsPage',
      // lazy load
      component: () => import('../page/SyncErrorsPage.vue'),
      meta: {
        onlyWhenLoggedOut: true,
        public: true,
        title: 'Sync Errors'
      }
    }
  ]
}
