import CMSLayout from '@/layout/CMSLayout.vue';
export const applicationKeyRouter = {
    path: '/account',
    component: CMSLayout,
    children: [
        {
            path: '/application-key',
            name: 'ApplicationKeyPage',
            component: () => import('../page/ApplicationKeyPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                isSuperAdmin:true,
                title: 'Application Key'
            },
        },
    ],
};
