import CMSLayout from '@/layout/CMSLayout.vue';
export const orderRouter = {
    path: '/account',
    component: CMSLayout,
    children: [
        {
            path: '/orders',
            name: 'OrderPage',
            // lazy load
            component: () => import('../page/OrderPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Orders',
            },
        },
    ],
};
