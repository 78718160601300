import { ActionContext, ActionTree, createStore, Store } from "vuex";
import { auth } from '@/modules/auth/store/auth';
export interface IRootState {
	// to display Loading component
	showLoading: boolean;
	targetConnection: string;
	reRender: boolean;
}

// set root state, action & mutation
const rootState = {
	showLoading: false,
	targetConnection: '',
	reRender: false,
};

const SET_SHOW_LOADING = "SET_SHOW_LOADING";

const rootActions: ActionTree<IRootState, IRootState> = {
	setLoading(
		context: ActionContext<IRootState, IRootState>,
		showLoading: boolean,
	) {
		context.commit(SET_SHOW_LOADING, showLoading);
	},
	reRender: function ({ commit }) {
		commit('TOGGLE_RE_RENDER')
	},
};

const rootMutations = {
	SET_SHOW_LOADING(state: IRootState, showLoading: boolean) {
		state.showLoading = showLoading;
	},
	setTargetConnection(state: IRootState, id: string) {
		state.targetConnection = id
	},
	TOGGLE_RE_RENDER: function (state: IRootState) {
		state.reRender = !state.reRender
	},
};

export const store = new Store({
	modules: {
		auth,
	},
	state: rootState,
	mutations: rootMutations,
	actions: rootActions,
});
