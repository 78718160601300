import CMSLayout from '@/layout/CMSLayout.vue';

export const getStartedRouter = {
    path: '/',
    component: CMSLayout,
    children: [
        {
            path: '/get-started',
            name: 'GetStartedPage',
            // lazy load
            component: () => import('../page/GetStarted.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Get Started'
            },
        },
    ],
}