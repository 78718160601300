const sidebars = [
  {
    name: 'Dashboard',
    url: 'dashboard',
    icon: 'chart-simple',
    isSuper: true,
    isClient: true
  },
  // {
  //   name: 'Catalog',
  //   url: 'catalog',
  //   icon: 'el-icon-coin',
  //   isSuper: false,
  //   isClient: true,
  //   children: {
  //     name: 'Product',
  //     subItem: [
  //       // { name: 'Attributes', url: 'attributes' },
  //       { name: 'Products', url: 'products' },
  //     ],
  //   },
  // },
  {
    name: 'Products',
    url: 'products',
    icon: 'tags',
    isSuper: false,
    isClient: true
  },
  {
    name: 'Orders',
    url: 'orders',
    icon: 'cart-plus',
    isSuper: false,
    isClient: true
  },
  {
    name: 'Sync Errors',
    url: 'sync-errors',
    icon: 'close',
    isSuper: false,
    isClient: true
  },
  // {
  //   name: 'Messages',
  //   url: 'messages',
  //   icon: 'el-icon-chat-square',
  //   isSuper: true,
  // },
  {
    name: 'Connections',
    url: 'connections',
    icon: 'cloud-arrow-up',
    isSuper: false,
    isClient: true
  },
  {
    name: 'Users',
    url: 'users',
    icon: 'circle-user',
    isSuper: true,
    isClient: true
  },
  {
    name: 'Notifications',
    url: 'notifications',
    icon: 'bell',
    isSuper: false,
    isClient: true
  },
  {
    name: 'Invoices',
    url: 'invoices',
    icon: 'file-invoice-dollar',
    isSuper: true,
    isClient: true
  },
  {
    name: 'Custom Invoice',
    url: 'custom-invoice',
    icon: 'file-invoice-dollar',
    isSuper: true,
    isClient: false
  },
  {
    name: 'Service Status',
    url: 'service-status',
    icon: 'info',
    isSuper: true,
    isClient: true
  },
  // {
  //   name: 'Packages',
  //   url: 'package',
  //   icon: 'box',
  //   isSuper: false,
  //   isClient: true,
  // },

  // {
  //   name: 'Settings',
  //   url: 'setting',
  //   icon: 'gear',
  //   isSuper: false,
  //   isClient: true,
  // },
  // {
  //   name: 'Crons',
  //   url: 'crons',
  //   icon: 'file-import',
  //   isSuper: false,
  //   isClient: true,
  // },
  {
    name: 'Account',
    url: 'accounts',
    icon: 'lock',
    isSuper: true,
    isClient: false
  },
  {
    name: 'Account Manager',
    url: 'account-manager',
    icon: 'lock',
    isSuper: false,
    isClient: false
  },
  // {
  //   name: 'Packages Manager',
  //   url: 'packages-manager',
  //   icon: 'box',
  //   isSuper: true,
  //   isClient: false,
  // },
  // {
  //   name: 'Application Key',
  //   url: 'application-key',
  //   icon: 'el-icon-key',
  //   isSuper: true,
  //   isClient: false,
  // },
  {
    name: 'Platform',
    url: 'platform',
    icon: 'question',
    isSuper: true,
    isClient: false
  },
  {
    name: 'Account Billing',
    url: 'account-billing',
    icon: 'question',
    isSuper: true,
    isClient: false
  },
  {
    name: 'Crons Manager',
    url: 'cron-manager',
    icon: 'question',
    isSuper: true,
    isClient: false
  },
  {
    name: 'Customization',
    url: 'script-custom',
    icon: 'question',
    isSuper: true,
    isClient: false
  },
  {
    name: 'Braintree API Keys',
    url: 'braintree-api-keys',
    icon: 'question',
    isSuper: true,
    isClient: false
  },
  {
    name: 'Templates',
    url: 'templates',
    icon: 'box',
    isSuper: true,
    isClient: false
  }
  // {
  //   name: 'Role Management',
  //   url: 'role-management',
  //   icon: 'el-icon-turn-off',
  //   isSuper: true,
  //   isClient: false,
  // },
]

export default [sidebars]
