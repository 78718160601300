import axios, { AxiosInstance, AxiosResponse } from "axios";
import { KEY_TOKEN_USER, HEADER_TOKEN_USER } from '@/modules/auth/store/auth.constant';

const baseURL = `${process.env.VUE_APP_BASE_URL}`;

const apiClient: AxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
    [HEADER_TOKEN_USER]: localStorage.getItem(KEY_TOKEN_USER) || '',

  },
  validateStatus: function (status) {
    return status >= 200 && status < 550;
  },
});

apiClient.interceptors.response.use(
  async (response: AxiosResponse) => {
      return response?.data; // simply return the response's data
  },
  error => {
      if (error.response) {
          error.response.data = {
              ...(error?.response?.data || {}),
              success: false,
          };
          return error.response?.data; // simply return the response's data
      } else if (error.request) {
          error.request.data = {
              ...(error?.request?.data || {}),
              success: false,
              message: error.message,
          };
          return error.request?.data;
      }
  },
);

export default apiClient;
