import { createI18n } from "vue-i18n";
import { layoutLang } from '@/layout/lang';
import { authLang } from '@/modules/auth/lang';
 
export const i18n = createI18n({
    locale: 'en',
    messages: {
      en: {
        layout: layoutLang.en,
        auth: authLang.en,
      },
    }
});
