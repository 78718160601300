import CMSLayout from '@/layout/CMSLayout.vue';
export const RoleManagementRouter = {
    path: '/account',
    component: CMSLayout,
    children: [
        {
            path: '/role-management',
            name: 'RoleManagement',
            component: () => import('../page/RoleManagement.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                isSuperAdmin: true,
                title: 'Role Management'
            },
        },
    ],
};
