import CMSLayout from '@/layout/CMSLayout.vue';

export const notFoundRouter = {
    path: '/',
    component: CMSLayout,
    children: [
        {
            path: '/404',
            name: '404',
            // lazy load
            component: () => import('../page/NotFound.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Page Not Found'
            },
        },
    ],
};
