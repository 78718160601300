import CMSLayout from '@/layout/CMSLayout.vue'

export const catalogRouter = {
  path: '/account',
  component: CMSLayout,
  children: [
    {
      path: '/attribute-mapping',
      name: 'AttributePage',
      // lazy load
      component: () => import('../page/ConnectionAttributeMappingPage.vue'),
      meta: {
        onlyWhenLoggedOut: true,
        public: false,
        title: 'Attribute Mapping'
      }
    },
    {
      path: '/products',
      name: 'ProductPage',
      // lazy load
      component: () => import('../page/ProductPage.vue'),
      meta: {
        onlyWhenLoggedOut: true,
        public: false,
        title: 'Products'
      }
    }
  ]
}
