import CMSLayout from '@/layout/CMSLayout.vue';
export const BraintreeAPIRouter = {
    path: '/braintree-api-keys',
    component: CMSLayout,
    children: [
        {
            path: '/braintree-api-keys',
            name: 'AccountPage',
            component: () => import('../page/braintree-api.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                isSuperAdmin:true,
                title: 'Braintree API Keys'
            },
        },
    ],
};
