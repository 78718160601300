import CMSLayout from '@/layout/CMSLayout.vue';

export const productRouter = {
    path: '/account',
    component: CMSLayout,
    children: [
        {
            path: '/product',
            name: 'ProductPage',
            // lazy load
            component: () => import('../page/ProductPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: false,
                title: 'Products'
            },
        },
    ],
}
