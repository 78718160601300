import CMSLayout from '@/layout/CMSLayout.vue';

export const ComfirmSubscriptionRouter = {
    path: '/',
    component: CMSLayout,
    children: [
        {
            path: '/comfirm-subscription',
            name: 'comfirm-subscription',
            // lazy load
            component: () => import('../page/comfirm-subscription.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: ''
            },
        }
    ],
}
