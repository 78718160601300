import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_el_main = _resolveComponent("el-main")

  return (_openBlock(), _createBlock(_component_el_main, {
    class: "app-main",
    style: {"overflow":"unset"}
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_router_view, { key: _ctx.reRender })
        ])
      ])
    ]),
    _: 1
  }))
}